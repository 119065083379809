.article {
  // todo
}

.author {
  font-size: 0.6em;
}

.lead {
  margin-top: 50px;
  font-weight: bold;
}
