*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

html[data-embed='true'],
html[data-embed='true'] body,
html[data-embed='true'] #root {
  height: auto;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
  cursor: default;
}

body {
  margin: 0;
  font-family: Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.6px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input,
strong,
p {
  margin: 0;
  padding: 0;
  border: 0;
}

/* video::-webkit-media-controls {
  display: none !important;
} */

@media screen and (max-height: 630px) {
  html,
  body {
    font-size: 80%;
  }
}

.hidden {
  display: none !important;
}

.width0 {
  width: 0% !important;
}
