.container {
  display: flex;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
  align-items: center;
}

.form {
  display: flex;
  flex-direction: column;
  width: 400px;
  margin-top: 30px;
}

.registerBox {
  margin: 20px 0 0;
}

.header {
  width: 100%;
  font-weight: bold;
  font-size: 20px;
  margin: 0 0 20px;
}
