.carousel {
  background: #e5f2ff;
  padding: 10px 60px;
}

.navLink,
.placeholder {
  width: 100%;

  @media (min-width: 1000px) {
    width: 31%;
  }
}

.navLink {
  display: flex;
  text-decoration: none;
  color: #000;
}

.articleContent {
  display: flex;
}

.articleImg {
  border: 5px #fff solid;
}

.articleLead {
  padding: 0 20px;
}

.buttonBox {
  padding: 20px;
}

.infodemia {
  text-align: center;
  padding: 40px;
  margin: 10px 0 0;
}

.infodemiaHeader {
  margin: 0 0 20px;
}

.arts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0 20px;
}

.art {
  width: 100%;
  margin: 20px 0;
  height: 300px;
  display: flex;
  background: #ccc none no-repeat center / cover;
}

.artFirst {
  width: 100%;
  height: 300px;
  margin: 20px 0;
  display: flex;
  border: 1px #76446b solid;
  background: #fff;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 1000px) {
    width: 31%;
  }
}

.artCategorySub {
  color: #76446b;
}

.artCategoryHeader {
  font-weight: bold;
  color: #76446b;
  font-size: 30px;
  margin: 20px;
}

.artBox {
  width: 100%;
  align-self: flex-end;
  position: relative;
}

.artCategoryDesc {
  margin: 10px 0 0;
  padding: 0 50px;
  text-align: center;
  color: #8a607f;
}

.artTitle {
  padding: 10px;
  background: #76446b;
  color: #fff;
  position: absolute;
  left: 1px;
  bottom: 93px;
}

.artName {
  padding: 10px;
  background: #76446b;
  color: #fff;
  font-weight: bold;
  margin: 0 0 20px;
  position: absolute;
  left: 1px;
  bottom: 30px;
}
