@import '../../styles/variables';

.slider {
  --swiper-navigation-color: #fff;
  --swiper-navigation-top-offset: 40%;

  padding-bottom: 100px;
  margin-bottom: 60px;
}

.slide {
  position: relative;
}

.figure {
  margin: 0;
  padding: 0;
  position: relative;
  height: 500px;

  @media (max-width: 700px) {
    height: 400px;
  }
}

.img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.box {
  display: flex;
  flex-flow: column;
  position: absolute;
  bottom: -70px;
  left: 50%;
  width: 430px;
  margin-left: 100px;
  padding: 20px 50px 50px;
  background: #e7cecd;

  @media (max-width: 1260px) {
    left: 15%;
    right: 15%;
    margin-left: 0;
    width: auto;
  }

  &,
  > * {
    transition: translate 0.4s, scale 0.4s, rotate 0.4s;

    &:nth-child(1) {
      transition-delay: 0.1s;
    }

    &:nth-child(2) {
      transition-delay: 0.15s;
    }

    &:nth-child(3) {
      transition-delay: 0.2s;
    }

    &:nth-child(4) {
      transition-delay: 0.25s;
    }

    &:nth-child(5) {
      transition-delay: 0.3s;
    }

    .slide:global(:not(.swiper-slide-active)):global(.swiper-slide-prev) & {
      translate: -100px 0;
    }

    .slide:global(:not(.swiper-slide-active)):global(.swiper-slide-next) & {
      translate: 100px 0;
    }
  }
}

.triangle {
  position: absolute;
  right: 100%;
  bottom: 100%;
  width: 170px;
  height: 180px;
  margin: 0 -70px -95px 0;
  rotate: 180deg;

  @media (max-width: 1260px) {
    scale: 0.6;
  }

  @media (max-width: 700px) {
    margin: 0 -90px -100px 0;
    scale: 0.5;
  }

  .slide:global(:not(.swiper-slide-active)):global(.swiper-slide-prev) & {
    scale: 0.25;
    rotate: 240deg;
  }

  .slide:global(:not(.swiper-slide-active)):global(.swiper-slide-next) & {
    scale: 0.25;
    rotate: 100deg;
  }

  > svg {
    width: 100%;
    height: 100%;
  }
}

.info {
  margin-left: auto;
  text-transform: uppercase;
  color: #76446b;
  font-weight: 300;
  text-align: right;
}

.title {
  margin: 110px 0 60px;
  color: #814a7a;
  font-size: 46px;
  font-weight: 300;
  line-height: 1.2;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (max-width: 1260px) {
    margin: 60px 0;
  }

  @media (max-width: 700px) {
    font-size: 30px;
    font-weight: 300;
  }

  @media (max-width: 480px) {
    font-size: 22px;
    font-weight: 400;
  }
}

.buttonWrap {
  align-self: center;
}

.button.button {
  background: 0 0;
  padding: 15px 25px;
  border: 1px solid #76446b;
  color: #76446b;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 1.4px;

  &:hover {
    background: #76446b;
    color: #fff;
  }
}
