.container {
  display: flex;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
  align-items: center;
}

.filters {
  margin: 40px auto;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  label {
    padding: 0 5px;
    background: #fff;
  }

  div {
    border-radius: 0;
  }

  > div {
    position: relative;
    margin-left: -1px !important;
  }

  > div:hover fieldset {
    z-index: 5;
  }

  fieldset {
    border-color: #ccc;
  }

  @media (min-width: 1000px) {
    flex-wrap: nowrap;
  }
}

.header {
  width: 100%;
  font-weight: bold;
  font-size: 20px;
  margin: 0 0 20px;
}

.articles {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

.articleImgBox {
  width: 100%;
}

.articleImg {
  width: 100%;
}

.article {
  width: 31%;
  margin: 0 0 60px;
  text-decoration: none;
  border: 1px #164c8a solid;
  padding: 0 30px;
  position: relative;
  overflow: hidden;

  :after {
    content: '';
    width: 100px;
    height: 100px;
    background: #c3c4d2;
    position: absolute;
    right: 0;
    bottom: 0;
    transform: rotate(50deg) translate(79px, 8px);
  }

  &:hover {
    :after {
      background: #164c8a;
    }
  }
}

.articleAvatar {
  position: relative;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;

  &:hover {
    z-index: 1;
    box-shadow: 0 0 0 2px #fff, inset 0 0 0 1px #c3c4d2;
  }

  & + & {
    margin-left: -30px;
  }
}

.articleCategory {
  text-transform: uppercase;
  color: #164c8a;
  font-size: 12px;
  margin: 10px 0 50px;
}

.articlePlaceholder {
  width: 22%;
}

.articleTitle {
  font-weight: bold;
  margin: 10px 0;
  color: #164c8a;
}

.articleLead {
  color: #6e759b;
}

.articleFooter {
  margin: 40px 0 20px;
  display: flex;
  align-items: center;
}

.articleAvatars {
  margin: 0 10px 0 0;
}

.articleAuthors {
  color: #164c8a;
  font-weight: 100;
  font-size: 12px;
}
