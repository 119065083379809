.header {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;
}

.title {
  position: relative;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #000;
  padding: 10px 15px;
  text-decoration: none !important;
}
