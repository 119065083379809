a {
  text-decoration: none;
}

.bigButtonsWrapper {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.flex50 {
  width: 50%;

  @media (max-width: 966px) {
    width: 100%;
  }
}

.horizontalDocument {
  border: 1px solid #164c8a;
  background: #efefef;
  display: flex;

  @media screen and (max-width: 766px) {
    flex-direction: column;
  }
}

.horizontalDocumentLeft {
  width: 100px;
  background: #164c8a;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 766px) {
    width: 100%;
    padding: 15px;
  }
}

.horizontalDocumentRight {
  padding: 20px 30px;
  color: #164c8a;
  position: relative;
  width: 95%;
  padding-bottom: 50px;

  @media screen and (max-width: 766px) {
    width: 100%;
  }
}

.horizontalDocumentLink {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 10px;
  color: #164c8a;
  text-decoration: none;
  border: 1px solid #164c8a;
  border-width: 1px 0 0 1px;

  &:hover {
    background: #164c8a;
    color: #fff;
  }
}

.container {
  display: flex;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
}

// .header {
//   width: 100%;
//   font-weight: bold;
//   font-size: 20px;
//   margin: 0 0 20px;
// }

.wytyczneTopWrap {
  display: flex;
  justify-content: 'center';
  gap: 30px;
  width: 100%;

  @media screen and (max-width: 766px) {
    flex-direction: column;
  }
}

.articles {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

.articleImgBox {
  width: 100%;
}

.articleImg {
  width: 100%;
}

.article {
  width: 31%;
  margin: 0 0 60px;
  text-decoration: none;
  border: 1px #164c8a solid;
  padding: 0 30px;
  position: relative;
  overflow: hidden;

  :after {
    content: '';
    width: 100px;
    height: 100px;
    background: #c3c4d2;
    position: absolute;
    right: 0;
    bottom: 0;
    transform: rotate(50deg) translate(79px, 8px);
  }

  &:hover {
    :after {
      background: #164c8a;
    }
  }
}

.articleAvatar {
  border-radius: 50%;
}

.articleCategory {
  text-transform: uppercase;
  color: #164c8a;
  font-size: 12px;
  margin: 10px 0 50px;
}

.articlePlaceholder {
  width: 22%;
}

.articleTitle {
  font-weight: bold;
  margin: 10px 0;
  color: #164c8a;
}

.articleLead {
  color: #6e759b;
}

.articleFooter {
  margin: 40px 0 20px;
  display: flex;
  align-items: center;
}

.articleAvatars {
  margin: 0 10px 0 0;
}

.articleAuthors {
  color: #164c8a;
  font-weight: 100;
  font-size: 12px;
}

.author {
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 1000px) {
    flex-wrap: nowrap;
  }
}

.authorLeft {
  flex: 1 1 auto;
  padding: 20px;
}

.authorName {
  font-weight: bold;
  font-size: 20px;
  color: #76446b;
}

.authorDesc {
  margin: 20px 0;
  color: #8a607f;
}

.authorTitle,
.authorUniversity,
.authorSpecialization {
  color: #76446b;
}

.authorTitle {
  margin: 30px 0 0;
}

.authorRight {
  width: 100%;

  @media (min-width: 1000px) {
    width: auto;
  }
}

.authorImg {
  flex-shrink: 0;
  width: 100%;
  height: 300px;
  background: none #ccc no-repeat center / cover;

  @media (min-width: 1000px) {
    width: 300px;
    height: 300px;
    margin: 40px;
  }
}
