.carousel {
  background: #e5f2ff;
  padding: 10px 60px;
}

.navLink,
.placeholder {
  width: 100%;

  @media (min-width: 1000px) {
    width: 31%;
  }
}

.navLink {
  background: #e5f2ff;
  margin: 20px 0;
  height: 517px;
  display: flex;
  text-decoration: none;
}

.articleContent {
  display: flex;
}

.articleImg {
  border: 5px #fff solid;
}

.articleLead {
  padding: 0 20px;
}

.articleDesc {
  -webkit-line-clamp: 5;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 120px;

  p {
    display: inline;
  }
}

.buttonBox {
  padding: 20px;
}

.infodemia {
  text-align: center;
  padding: 40px;
  margin: 50px auto 0;
  color: #76446b;
}

.infodemiaHeader {
  width: auto;
  margin: auto;
  background: #e8cece;
  padding: 5px 20px;
  display: inline-block;
  position: relative;

  .headerTriangleIcon {
    position: absolute;
    z-index: 5;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -10%) rotate(90deg);
    width: 30px;
  }

  &.infodemiaHeaderPublications {
    background: #164c8a;
    color: #e7e8f5;
    margin: 0 auto 0 0;
  }

  &.infodemiaHeaderEducation {
    background: #e75f5d;
    color: #fff;
    margin: auto;
  }
}

.infodemiaMainText {
  width: 60%;
  margin: auto;
  padding: 30px;
  background: #76446b;
  color: #fff;

  @media (max-width: 1024px) {
    width: 100%;
  }
}

.arts {
  display: flex;
  justify-content: space-around;
  margin: 100px auto 0;
  max-width: 1340px;
  width: 100%;
  flex-wrap: wrap;

  @media (min-width: 1000px) {
    flex-wrap: wrap;
  }
}

.art {
  width: 100%;
  background: #e5f2ff;
  height: 517px;
  display: flex;
  position: relative;
}

.artFirst {
  width: 100%;
  height: 517px;
  margin: 20px 0;
  display: flex;
  background: #efada9;
  align-items: center;
  flex-direction: column;
  position: relative;
  justify-content: center;

  svg {
    position: absolute;
    left: 100%;
    top: 50%;
    z-index: 999;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  @media (min-width: 1000px) {
    width: 31%;
  }
}

.patientArts {
  .artFirst {
    background: #d7cbd0;
    color: #ef4161;
  }

  .artBoxBg {
    background: #d7cbd0;
  }

  .artBox:hover {
    background: #d7cbd0;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  }

  .artBox,
  .artCategorySub,
  .artCategoryHeader {
    color: #ef4161;
  }
}

.artCategorySub {
  color: #76446b;
}

.artCategoryHeader {
  font-weight: bold;
  color: #76446b;
  font-size: 30px;
  margin: 20px;
}

.artCategory {
  margin: 0 0 10px;
  text-transform: uppercase;
  z-index: 5;
  position: relative;
  font-size: 12px;
}

.artImg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.artBoxBg {
  width: 100%;
  height: 100%;
  align-self: flex-end;
  background: rgba(239, 173, 169, 1);
  mix-blend-mode: color;
  padding: 20px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #76446b;
  z-index: 1;
  transition: all 0.3s ease;
}

.artBox {
  width: 100%;
  z-index: 5;
  height: 100%;
  align-self: flex-end;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: all 0.3s ease;
  color: #76446b;

  &:after {
    inset: 0;
    position: absolute;
    z-index: 0;
    content: ' ';
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 8%,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &:hover {
    background: rgba(239, 173, 169, 1);
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  }
}

.artTitle {
  font-weight: bold;
  font-size: 18px;
  position: relative;
  z-index: 5;
  margin: 0 0 10px;
}

.articles {
  display: flex;

  // justify-content: space-between;
  color: #164c8a;
  gap: 30px;
  margin: 40px auto 0;
  flex-wrap: wrap;

  &.marginTop100 {
    margin-top: 100px;
  }

  &.noMarginTop {
    margin-top: 0;
  }

  @media (min-width: 1000px) {
    flex-wrap: nowrap;
  }

  a {
    text-decoration: none;
    color: inherit;
    transition: all 0.4s ease;
    height: 50%;

    &:hover {
      box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
    }
  }
}

.articlesLeft {
  display: flex;

  .articleMainContent {
    flex: auto;
  }

  .articleDesc {
    -webkit-line-clamp: 8;
    max-height: 190px;
  }
}

.articlesLeft,
.articlesRight {
  width: 100%;
  flex-direction: column;

  @media (min-width: 1000px) {
    width: 49%;
  }
}

.articleMain {
  display: flex;
  flex-direction: column;
  background: #e7e8f4;
}

.articleMainImg {
  width: 100%;
  height: 235px;
  max-height: 100%;
  object-fit: cover;
}

.articleMainTop,
.articleMainContent {
  width: 100%;
  display: flex;
  flex-direction: column;

  p {
    margin-block: 0 !important;
  }
}

.articleMainTop {
  // height: 250px;

  @media (min-width: 600px) {
    // height: 350px;
  }
}

.articleMainContent {
  background: #e7e8f4;
  padding: 30px;
}

.articleRight {
  background: #e7e8f4;
  display: flex;

  // margin: 20px 0 0;
  flex-direction: column-reverse;

  @media (min-width: 1000px) {
    flex-direction: row;
    margin: 0;
  }
}

.articleTitle {
  width: 100%;
  margin: 0 0 20px;
  font-weight: 700;
}

.articlesRight {
  display: flex;
  flex-direction: column;

  // justify-content: space-between;
  gap: 30px;
}

.articleRightImgBox {
  width: 100%;
  display: none;

  @media (min-width: 1000px) {
    width: 70%;
  }
}

.artCategoryHeaderIcon {
  @media (max-width: 1000px) {
    display: none;
  }
}

.educationBoxes {
  display: flex;
  width: 100%;
  margin-bottom: 1px;

  @media screen and (max-width: 766px) {
    flex-direction: column;
  }
}

.educationImgWrap {
  position: relative;
  overflow: hidden;
}

.educationImg {
  width: 100%;
  position: relative;
  padding-top: -20%;
}

.educationBox {
  height: 220px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #e75f5d;
  background: #f5dbcb;
  flex: 1;
  z-index: 0;
  font-size: 1.3rem;
  font-weight: 500;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  }

  span {
    display: inline-block;
    padding: 20px;
    transition: all 0.3s ease;
    background: #fff;

    &:hover {
      box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
    }
  }

  .cat {
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size: 10px;
    color: #e75f5d;
  }

  @media screen and (max-width: 766px) {
    height: auto;
    font-size: 1.4rem;
    padding: 40px 15px;
  }
}

.educationBox:nth-child(2) {
  border: 1px solid #fff;
  border-width: 0 1px;

  @media screen and (max-width: 766px) {
    border-width: 1px 0;
  }
}

.partnersWrapOthers {
  display: flex;
  width: 100%;
  gap: 30px;

  @media screen and (max-width: 766px) {
    flex-direction: column;
  }
}

.partnersWrapPartner {
  display: flex;
  flex-direction: row;
  width: 50%;
  gap: 1px;
  text-decoration: none;

  @media screen and (max-width: 766px) {
    flex-direction: column;
    flex-basis: 1 1 100%;
    width: 100%;
  }
}

.partnersWrapPartner.partnersWrapCochrane {
  padding: 30px 10% 30px 30px;
  margin-bottom: 30px;
  margin-top: 30px;
  color: #1e4778;
  width: 100%;

  // border: 2px solid #1e4778;
  background-image: url(/assets/images/pics/main-page/cochrane_right.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: 99% center;
  background-color: #e7e8f5;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  }

  @media screen and (max-width: 766px) {
    padding: 15px;
    background-image: none;
  }

  .partnersWrapPartnerText {
    widows: 85%;
    padding-left: 4%;
  }
}

.partnersWrapPartnerText {
  width: 70%;
  padding-left: 50px;

  @media screen and (max-width: 766px) {
    width: 100%;
    padding-left: 0;
  }
}

.partnersWrapPartnerLogo {
  width: 30%;

  @media screen and (max-width: 766px) {
    width: 100%;
  }
}

.partnersWrapCochraneLogo {
  width: 11%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 60%;

    &.cochraneLogo {
      display: none;
    }
  }

  @media screen and (max-width: 766px) {
    width: 100%;

    .cochraneLogoPion {
      display: none;
    }

    img.cochraneLogo {
      display: block;
    }
  }
}

.partnersWrapPartner:nth-child(2) .partnersWrapPartnerLogo {
  background: #02436d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.partnersWrapPartner:nth-child(2) .partnersWrapPartnerLinks a {
  background: #02436d;

  &:hover {
    background: #013a5f;
  }
}

.partnersWrapPartnerLogo img {
  width: 100%;
}

.partnersWrapPartnerLinks {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1px;

  @media screen and (max-width: 766px) {
    width: 100%;
  }
}

.partnersWrapPartnerLinks a {
  width: 100%;
  text-decoration: none;
  font-size: 1.4rem;
  height: 50%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  align-items: center;
  background: #01a2d6;
  color: #fff;
  transition: all 0.3s ease;
}

.partnersWrapPartnerLinks a:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  background: #028ebd;
}

.partnersWrapPartnerLinks a span {
  display: block;
  font-size: 0.8rem;
}

.mainBoxes {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 80%;
  margin: auto;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}

.mainBox {
  flex: 1 1 23%;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #76446b;
  font-size: 1rem;
  font-weight: 800;
  margin-top: 30px;
  position: relative;

  @media screen and (max-width: 766px) {
    flex: 1 1 100%;
  }

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    :after {
      position: absolute;
      right: 0;
      bottom: -23px;
      height: 20px;
      border-right: 1px dashed #76446b;
      content: ' ';
    }

    :before {
      position: absolute;
      right: 0;
      bottom: -23px;
      width: 20px;
      border-bottom: 1px dashed #76446b;
      content: ' ';
    }
  }

  &:nth-child(5),
  &:nth-child(6),
  &:nth-child(7) {
    :after {
      position: absolute;
      right: 0;
      top: -23px;
      height: 20px;
      border-right: 1px dashed #76446b;
      content: ' ';
    }

    :before {
      position: absolute;
      left: 100%;
      top: -23px;
      width: 20px;
      border-bottom: 1px dashed #76446b;
      content: ' ';
    }
  }

  @media (max-width: 860px) {
    :before,
    :after {
      display: none;
    }
  }
}

.mainBoxIcon {
  margin-bottom: 10px;
  opacity: 1;

  img {
    width: 60px;
    opacity: 1;
  }
}

.mainBox span {
  font-size: 0.8rem;
  font-weight: 400;
  display: block;
  margin-top: 5px;
}
