.dialog {
  // todo
}

.form {
  margin: 30px 0 0;
}

.code {
  :global(.MuiInputBase-input) {
    font-family: monospace;
  }
}
