.container {
  display: flex;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
}

.slider {
  --swiper-navigation-color: #fff;
  --swiper-navigation-top-offset: 40%;

  width: 100%;
}

.swiper-button-next,
.swiper-button-prev {
  color: #efefef;
}

.slide {
  position: relative;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.figure {
  margin: 0;
  padding: 0;
  position: relative;
  height: 700px;
  text-align: center;

  @media (max-width: 1245px) {
    height: 500px;
  }

  @media (max-width: 700px) {
    height: 300px;
  }
}

.img {
  position: absolute;
  top: 0;
  height: 100%;
  left: 50%;
  max-width: 100%;
  transform: translateX(-50%);
  object-fit: cover;

  @media (max-width: 1245px) {
    width: 100%;
    height: auto;
  }
}

.podcastsWrapper {
  display: flex;
  gap: 50px;
  margin-bottom: 50px;

  @media (max-width: 766px) {
    flex-direction: column;
  }
}

.podcastBox {
  width: 33%;
  font-size: 1rem;
  font-weight: bold;
  border: 1px solid #f03f61;
  padding: 5px;

  @media (max-width: 766px) {
    width: 100%;
  }
}

.podcastTitle {
  padding: 20px;
  text-align: center;
  color: #f03f61;
}

.horizontalDocument {
  border: 1px solid #164c8a;
  background: #efefef;
  display: flex;
  width: 100%;

  @media (max-width: 766px) {
    flex-direction: column;
  }
}

.horizontalDocumentLeft {
  width: 110px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 766px) {
    width: 100%;
    text-align: center;
  }
}

.horizontalDocumentLeft img {
  width: 100%;

  @media (max-width: 766px) {
    width: 50%;
  }
}

.horizontalDocumentRight {
  padding: 20px 30px;
  color: #164c8a;
  position: relative;
  width: 95%;
  padding-bottom: 50px;
}

.horizontalDocumentLink {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 10px;
  color: #164c8a;
  text-decoration: none;
  border: 1px solid #164c8a;
  border-width: 1px 0 0 1px;
}

.flexWrap {
  display: flex;
  width: 100%;
  gap: 30px;

  @media (max-width: 766px) {
    flex-direction: column;
  }
}

.flex50 {
  width: 50%;

  @media (max-width: 966px) {
    width: 100%;
  }
}
