.container {
  padding: 24px 0;
}

.header {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 20px;
}

.articles {
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 1000px) {
    flex-wrap: nowrap;
  }
}
