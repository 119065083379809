.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 0;
}

.littleHeader {
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: bold;
}

.header {
  font-size: 26px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 15px;
}

.description {
  text-align: center;
  margin: 0 0 24px;
  font-size: 15px;
}
