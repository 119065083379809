.articles {
  --gap: 36px;
  --columns: 3;
  --color: #164c8a;

  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: var(--gap) 0 0;
  gap: var(--gap);

  @media (max-width: 1138px) {
    --gap: 20px;
    --columns: 2;
  }

  @media (max-width: 800px) {
    --gap: 20px;
    --columns: 1;
  }
}

.article {
  width: calc(
    (100% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns))
  );
  min-height: 360px;
  text-decoration: none;
  border: 1px solid var(--color);
  background: #fff;
  position: relative;
}

.articlePlaceholder {
  width: 22%;
}

.articleAdd {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  font-size: 19px;
  color: #76446b;
  text-align: center;
  height: 100%;

  > * {
    &:nth-child(1) {
      text-transform: uppercase;
    }

    &:nth-child(2) {
      margin: 10px 0 20px;
      font-size: 36px;
    }
  }
}

.articleLink {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  padding: 14px 34px;
  text-decoration: none;
  color: var(--color);
  transition: box-shadow 0.3s;

  &:is(a):hover {
    box-shadow: 0 4px 29px 0 rgba(0, 0, 0, 0.25);
  }
}

.articleHeader {
  display: flex;
  flex-wrap: wrap;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border: 34px solid transparent;
    border-color: transparent currentcolor currentcolor transparent;
    opacity: 0.25;

    @supports (color: color-mix(in srgb, var(--color) 15%, black 10%)) {
      color: color-mix(in srgb, var(--color) 15%, black 10%);
      opacity: 1;
    }

    .articleLink:hover & {
      color: var(--color);
      opacity: 1;
    }
  }

  > * {
    width: 50%;

    &:only-child {
      width: 100%;
    }

    &:nth-child(even) {
      text-align: right;
    }

    &:nth-child(2),
    &:nth-child(3) {
      color: #000;
    }

    &:nth-child(1) {
      font-weight: 300;
    }
  }
}

.articleContent {
  margin-top: auto;

  &:last-child {
    margin-bottom: 80px;
  }
}

.articleTitle {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 80px 0 18px;
  font-size: 22px;
  font-weight: 600;

  .articleLink:hover & {
    text-decoration: underline;
  }
}

.articleLead {
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 19px;
  font-weight: 400;
  opacity: 0.7;

  @supports (color: color-mix(in srgb, var(--color) 70%, black 5%)) {
    color: color-mix(in srgb, var(--color) 70%, black 5%);
    opacity: 1;
  }
}

.articleFooter {
  display: flex;
  align-items: center;
  margin: 38px 0 0;
  font-size: 14px;
  font-weight: 300;
}

.articleAvatars {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 14px;
}

.articleAvatar {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #ccc;
  border: 0;
  box-shadow: 0 0 0 1px rgba(#fff, 0.5);
  object-fit: cover;

  &:hover {
    z-index: 1;
  }
}

.articleAvatar + .articleAvatar {
  margin-left: -30px;
}

.articleAvatarMore {
  width: 24px;
  height: 24px;
  margin-left: -18px !important;
  font-size: 10px;
  font-weight: bold;
}

.articleAuthor {
  span {
    font-size: 10px;
    font-weight: bold;
  }
}
