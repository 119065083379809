.alert {
  background: rgba(0, 0, 0, 0.93);
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  text-align: center;
  padding: 50px 50px 0;
  color: #fff;
  font-size: 1rem;
}

.acceptButton {
  border: 1px solid #fff;
  background: rgba(11, 98, 64, 0.15);
  color: #fff;
  height: 44px;
  line-height: 42px;
  padding: 0 15px;
  font-size: 1.2rem;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.acceptButton:hover {
  background: rgba(11, 98, 64, 0.3);
}
