.article {
  // todo
}

.articleEmbeded {
  display: block;
  background: #6f3263;
  color: #f4d0c9;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  padding: 15px;

  mark {
    margin: 0 10px;
    background: inherit;
    color: inherit;
    text-decoration: underline;
  }

  svg {
    vertical-align: text-top;
  }
}

.header {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  min-height: 455px;
  padding: 24px 48px;

  @media (max-width: 1000px) {
    min-height: 355px;
  }

  @media (max-width: 600px) {
    min-height: 255px;
  }
}

.headerFigure {
  margin: 0;
  position: absolute;
  inset: 0;
  background: #ccc;

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    background: #164c8a;
    mix-blend-mode: screen;
  }
}

.headerImage {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title {
  position: relative;
  z-index: 1.1;
  max-width: 1096px;
  margin: 150px auto 11px;
  padding: 0.125em 0 0.25em;
  font-size: 64px;
  font-weight: 300;
  line-height: 1;
  text-align: center;
  color: #f4d0c9;

  @media (max-width: 1000px) {
    font-size: 48px;
  }

  @media (max-width: 600px) {
    font-size: 32px;
  }
}

.titleOuter {
  display: inline;
  padding: 0.125em 0 0.25em;
  background-color: #164c8a;
  box-shadow: 0.375em 0 0 #164c8a, -0.375em 0 0 #164c8a;
  box-decoration-break: clone;
}

.titleInner {
  display: inline;
  position: relative;
  z-index: 1;
}

.container {
  display: flex;
  flex-flow: column;
  max-width: calc(2 * 30px + 1096px);
  margin: 0 auto;
  padding: 0 30px;

  @media (max-width: 800px) {
    padding: 0 15px;
  }
}

.authorBoxList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-self: center;
  width: 600px;
  max-width: 100%;
}

.authorBox {
  font-size: 12px;
  max-width: 200px;
  margin: 24px 0 12px;
  padding: 0 10px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.authorBox:nth-last-child(n + 2),
.authorBox:nth-last-child(n + 2) ~ .authorBox {
  font-size: 9px;
}

.authorBox:nth-last-child(n + 3),
.authorBox:nth-last-child(n + 3) ~ .authorBox {
  font-size: 6px;
}

.authorBox:nth-last-child(n + 2):nth-last-child(-n + 2):first-child,
.authorBox:nth-last-child(n + 2):nth-last-child(-n + 2):first-child
  ~ .authorBox {
  width: 50%;
}

.authorBox:nth-last-child(n + 3):nth-last-child(-n + 3):first-child,
.authorBox:nth-last-child(n + 3):nth-last-child(-n + 3):first-child
  ~ .authorBox {
  width: 33.333%;
  max-width: 150px;

  @media (max-width: 480px) {
    width: 50%;
  }
}

.authorBox:nth-last-child(n + 4),
.authorBox:nth-last-child(n + 4) ~ .authorBox {
  width: 25%;

  @media (max-width: 480px) {
    width: 50%;
  }
}

.authorAvatar {
  width: 10em;
  height: 10em;
  border-radius: 100%;
  background: #ccc;
  border: 0;
  object-fit: cover;
}

.authorName {
  margin-top: 1.333em;
  font-size: 1.833em;
  font-weight: 600;
  color: #164c8a;
  text-align: center;
}

.date {
  align-self: center;
  color: #485b87;
  font-size: 19px;
  font-weight: 400;
}

.articleShare {
  margin: 20px auto 0;
  color: #164c8a;
}

.contentWrap {
  margin: 24px 0;
  font-size: 19px;
  display: flex;
  text-align: justify;

  p {
    text-indent: 50px;
  }

  @media screen and (max-width: 800px) {
    font-size: 16px;

    p {
      text-indent: 20px;
    }
  }
}

.contentText {
  width: 100%;
  word-wrap: break-word;
}

.tocNav {
  position: sticky;
  top: 90px;
  align-self: flex-start;
  flex: 0 0 auto;
  width: 30%;
  max-width: 330px;
  margin-right: 60px;

  * {
    transition: opacity 0.3s;
  }

  @media (max-width: 800px) {
    display: none;
  }
}

.tocNavChapter {
  &[data-level='1'] {
    margin-top: 26px;
    border-radius: 20px;
    padding: 10px 20px;
    background: #f1f2fa;
    color: #164c8a;

    &[data-is-active] {
      background: #164c8a;
      color: #fff;
    }

    > .tocNavTitle {
      font-size: 20px;
    }
  }

  &[data-level='2'] {
    .tocNavTitle {
      font-size: 16px;
      opacity: 0.75;

      &:after {
        padding: 0 15px;
        background-size: 8px 5px;
        font-size: 11px;
      }
    }

    .tocNavChildren {
      margin: 10px 0;
    }
  }

  &:not([data-is-active]) > .tocNavChildren {
    display: none;
  }

  &[data-is-active] {
    .tocNavTitle:not([data-children='0']):after {
      background-image: url(../../assets/icons/ArrowDownWhite.svg);
    }

    > .tocNavTitle {
      font-weight: 700;
      opacity: 1;

      &:after {
        content: '';
        background-position: left center;
        rotate: 180deg;
      }
    }
  }
}

.tocNavChildren {
  margin: 14px 0;
}

.tocNavTitle {
  display: flex;
  margin: 6px 0;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  color: inherit;

  span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:after {
    content: '';
    margin: 2px 6px 2px auto;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 800;
  }

  &:not([data-children='0']):after {
    content: attr(data-children);
    background: url(../../assets/icons/ArrowDownBlue.svg) no-repeat right center /
      15px 9px;
  }
}

.toc {
  display: block;
}

.lead {
  font-weight: 600;
  line-height: 1.5;
}

.tocHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #164c8a;
}

.tocShare {
  flex: 0 0 auto;
  margin-left: 20px;
}

@mixin content-children {
  line-height: 1.5;

  p {
    margin: 0.75em 0;

    &:first-child {
      margin-top: 0;
    }
  }

  pre {
    white-space: pre-line;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1em 0 0.75em;
    font-weight: 700;
    line-height: 1.3;
    color: #000;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.8em;
  }

  h3 {
    font-size: 1.6em;
  }

  h4 {
    font-size: 1.4em;
  }

  h5 {
    font-size: 1.2em;
  }

  h6 {
    font-size: 1em;
  }

  ul,
  ol {
    margin: 1em 0;
    text-align: left;
  }

  li {
    padding-left: 1em;

    &::marker {
      color: #000;
    }
  }

  blockquote {
    margin: 30px 0;
    background: #e1e1e1;
    color: #000;
  }

  a {
    font-weight: bold;
    color: #76446b;

    &:hover {
      text-decoration: underline;
    }
  }

  p > *:not(figure) > img {
    width: auto;
    max-width: 100%;
  }

  > img,
  p > img,
  > figure {
    display: block;
    width: 100% !important;
    height: auto !important;
    max-width: none !important;
    margin: 2.1em 0;
  }

  > iframe,
  p iframe {
    display: block;
    width: 100%;
  }

  figure {
    position: relative;
    display: block;

    img {
      display: block;
      width: 100% !important;
      height: auto !important;
    }
  }
}

.content {
  @include content-children;
}

.similarArticlesHeader {
  color: #76446b;

  @media (max-width: 1300px) {
    padding: 0 70px;
  }

  span {
    color: #000;
  }
}

.similarArticlesContainer {
  @media (max-width: 1138px) and (min-width: 801px) {
    article:last-child {
      display: none;
    }
  }
}

.passwordNeeded {
  display: flex;
  flex-flow: column;
  gap: 30px;
  margin: 100px 20px 50px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
