.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: all 0.5s ease;

  &:hover {
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  }

  @media (min-width: 1000px) {
    width: 240px;
    margin: 0 24px 0 0;
  }
}

.navLink {
  text-decoration: none;
  display: flex;
  margin: 0 0 10px;
  width: 100%;
}

.image {
  width: 100%;
  vertical-align: middle;
}

.textBox {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px 0;
  flex-grow: 1;
  min-height: 152px;
  justify-content: center;
}

.text {
  color: #000;
  margin: 0 0 13px;
  padding: 0 20px;
  text-transform: uppercase;
  font-size: 13px;

  span {
    font-weight: bold;
    display: block;
    font-size: 15px;
  }
}

.goTo {
  color: #000;
}
