@import '../../styles/variables';

.mainBody {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background: $main-background;
  background: url(/assets/images/pics/layout/main_page_bg.png);
  background-position: center top;
  background-size: 100% auto;
}

.navPanel {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 90px;
  z-index: 1300;
  padding: 15px;
  background: #fff;
  position: fixed;
}

.mainPanel {
  flex: 1 1 auto;
  width: 100%;
  margin-top: 100px;

  @media (min-width: 700px) {
    padding: 0 70px 138px;
    margin-top: 110px;
  }

  html[data-embed='true'] & {
    margin-top: 0;
  }
}

.link {
  display: flex;
}

.logo {
  width: 200px;
  display: flex;
  margin: 0 20px 0 0;
  min-width: 100px;

  @media (min-width: 1200px) {
    margin: 0 40px 0 0;
  }

  img {
    width: 100%;
    height: auto;
    align-self: center;
  }
}

.mainUeBox {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 0 10px 10px;
  display: inline-block;
}

.mainUe {
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 10px;
  margin: auto;
  margin-bottom: 30px;
  display: block;

  @media (max-width: 700px) {
    margin-bottom: 10px;
  }

  img {
    width: auto;
    height: 50px;
    vertical-align: middle;
    margin-right: 10px;

    @media (max-width: 700px) {
      height: 30px;
    }
  }
}

.navbar {
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  list-style-type: none;
  padding: 0;

  li {
    margin: 0 20px 0 0;
    padding: 10px;

    a {
      text-decoration: none;
      display: flex;
      width: 100%;
      color: $main-black;
    }
  }
}

.active {
  background-color: $button-blue;
  border-radius: 10px;

  a {
    color: $main-white !important;
  }
}

.footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #3a3a3c;
    content: ' ';
    height: 50%;
    z-index: 0;
    min-height: 120px;

    @media (max-width: 700px) {
      height: 100%;
    }
  }

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
}

.footerLeft {
  width: 90%;
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: row;
  margin: 0 0 0 30px;
  font-size: 11px;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  z-index: 5;

  @media (max-width: 700px) {
    margin: 0 0 30px;
    width: 100%;
    max-width: 90%;
    text-align: center;
    flex-direction: column;
  }

  a.kifLogo {
    width: 200px;
    padding-right: 30px;
    border-right: 1px dashed #fff;
    margin-right: 30px;
    justify-self: flex-end;

    @media (max-width: 700px) {
      border: none;
      padding: 0;
      margin: 0 0 30px;
    }

    img {
      width: 100%;
    }
  }

  a:not(.footerLogo) {
    color: #fff;
    display: inline-block;
    text-decoration: none;
    margin-left: 30px;
    font-size: 12px;

    @media (max-width: 700px) {
      display: block;
      margin-bottom: 20px;
    }
  }
}

.footerRight {
  position: relative;
  z-index: 5;
  text-align: right;
}

.footerLogo {
  width: 300px;
  position: absolute;
  margin-top: 50px;
  display: none;
  bottom: 40%;

  img {
    display: block;
    width: 100%;
    height: auto;

    @media (max-width: 700px) {
      width: 220px;
      display: none;
      height: auto;
      margin: auto;
    }
  }
}

.footerSign {
  width: 56%;
  max-width: 849px;
  height: auto;
}
