.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;

  @media (min-width: 1000px) {
    flex-wrap: nowrap;
  }
}

.title {
  font-size: 20px;
  font-weight: 600;
  font-weight: bold;
}

.category {
  position: absolute;
  top: 100%;
  text-transform: uppercase;
  left: 20px;
  font-size: 12px;
  transform: translateY(-200%);
}

.author {
  font-size: 19px;
  font-weight: 400;
  margin: 0 0 20px;
  color: #000;
}

.imageBox {
  width: 100%;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    background: var(--color);
    mix-blend-mode: color;
  }
}

.image {
  width: 100%;
  height: 385px;
  background: #ccc;
  object-fit: cover;

  @media (min-width: 1000px) {
    width: 343px;
  }
}

.content {
  margin: 0 20px 0 10px;
  padding: 20px 20px 50px;
  position: relative;
  background: rgba(255, 255, 255, 0.7);

  @media (min-width: 1000px) {
    transform: translateX(-100px);
  }
}

.navLink {
  color: #000;
  text-decoration: none;
  flex: 1 1 0;
}
