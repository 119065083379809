.navLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 0 10px;
  font-size: 12px;
  white-space: nowrap;

  @media (min-width: 1220px) {
    margin: 0 15px;
    font-size: 14px;
  }
}

.navLink:nth-child(1) {
  display: none;

  @media (min-width: 1300px) {
    display: flex;
  }
}

.icon {
  width: 20px;
  margin: 0 10px 0 0;
}

.label {
  color: #000;
}

.box {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}

.navItems {
  display: none;

  @media (min-width: 1000px) {
    display: flex;
    justify-content: flex-end;
  }
}

.navMobileItems {
  background: #000;

  @media (min-width: 1000px) {
    display: none;
  }
}
